<i18n>
{
  "de": {
    "nameTitle": "Heizungstyp",
    "typeTitle": "Typ",
    "sourceTitle": "Wärmequelle",
    "energyCarrierTitle": "Energieträger",
    "gridEfficiencyTitle": "Wärmenetz Effizienz",
    "rhEfficiencyTitle": "Nutzungsgrad Raumwärme",
    "dhwEfficiencyTitle": "Nutzungsgrad Warmwasser",
    "heatpumpGradeTitle": "Gütegrad",
    "duplicateNameErrorMessage": "Name muss innerhalb des Portfolios einzigartig sein."
  }
}
</i18n>

<template>
  <EditContainer class="edit-heating-type" :bind-form-state="formState" @container="onFormEvent">
    <FormRow :label="$t('nameTitle')">
      <div v-if="heatingType && heatingType.default">
        {{ $t(`_heatingTypes.${heatingType.name}`) }}
      </div>
      <TextInput
        v-else
        v-model="model.name"
        :edit="isEditing"
        :invalidValues="exisitingHeatingTypes"
        :invalidValuesErrorMessage="$t('duplicateNameErrorMessage')"
        @validation="onValidation($event, 'name')"
      />
    </FormRow>
    <FormRow :label="$t('energyCarrierTitle')">
      <div v-if="heatingType && heatingType.default" :class="energyCarrierClasses">
        {{ energyCarrierTranslated }}
      </div>
      <EnergyCarrierPicker v-else v-model="model.energy_carrier_id" :portfolio="portfolio" :edit="isEditing" />
    </FormRow>
    <FormRow :label="$t('gridEfficiencyTitle')">
      <NumericInput
        v-model="model.grid_efficiency"
        noFormatting
        float
        :edit="isEditing"
        @validation="onValidation($event, 'grid_efficiency')"
      />
    </FormRow>
    <div v-if="type === 'BOILER'">
      <FormRow :label="$t('rhEfficiencyTitle')">
        <NumericInput
          v-model="model.boiler_rh_efficiency"
          noFormatting
          float
          :edit="isEditing"
          @validation="onValidation($event, 'boiler_rh_efficiency')"
        />
      </FormRow>
      <FormRow :label="$t('dhwEfficiencyTitle')">
        <NumericInput
          v-model="model.boiler_dhw_efficiency"
          noFormatting
          float
          :edit="isEditing"
          @validation="onValidation($event, 'boiler_dhw_efficiency')"
        />
      </FormRow>
    </div>
    <div v-else>
      <FormRow :label="$t('heatpumpGradeTitle')">
        <NumericInput
          v-model="model.heatpump_grade"
          noFormatting
          float
          :edit="isEditing"
          @validation="onValidation($event, 'heatpump_grade')"
        />
      </FormRow>
      <FormRow :label="$t('sourceTitle')">
        <v-select
          v-if="isEditing"
          v-model="model.source"
          :options="[
            { id: 'AIR', label: $t('_heatPumpSources.AIR') },
            { id: 'GW', label: $t('_heatPumpSources.GW') },
            { id: 'EWS', label: $t('_heatPumpSources.EWS') },
            { id: 'GENERIC', label: $t('_heatPumpSources.GENERIC') },
          ]"
          :clearable="false"
          :reduce="(t) => t.id"
        />
        <div v-else>{{ $t(`_heatPumpSources.${model.source}`) }}</div>
      </FormRow>
    </div>
  </EditContainer>
</template>
<script>
import { mapActions } from 'vuex'

import ApiFormMixin from '@/components/shared/forms/ApiFormMixin.vue'
import EditContainer from '@/components/shared/forms/EditContainer.vue'

import TextInput from '@/components/shared/forms/TextInput.vue'
import NumericInput from '@/components/shared/forms/NumericInput.vue'
import FormRow from '@/components/shared/forms/FormRow.vue'

import EnergyCarrierPicker from '@/components/shared/EnergyCarrierPicker.vue'

export default {
  mixins: [
    ApiFormMixin,
    /* ApiFormMixin:
      - Requires implementation of the following methods: resetModel, saveHandler, isValid (optional)
      - Provides: model, isValid, isEditing, formState, onFormEvent, onValidation,
                  getBuildingPermission, getPortfolioPermission
    */
  ],

  components: {
    TextInput,
    NumericInput,
    EditContainer,
    FormRow,
    EnergyCarrierPicker,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    heatingType: {
      type: Object,
    },
    type: {
      type: String,
      required: true,
    },
  },

  computed: {
    exisitingHeatingTypes() {
      if (this.heatingType && this.heatingType.id) {
        // if editing an existing heating type, exclude the current heating type from the list of invalid values
        return this.portfolio.heating_types.filter((ht) => ht.id !== this.heatingType.id).map((ht) => ht.name)
      } else {
        // if id is null (creating a new one), exclude all heating types
        return this.portfolio.heating_types.filter((ht) => ht.id).map((ht) => ht.name)
      }
    },

    //
    energyCarrierIndex() {
      return this.portfolio.energy_carriers.findIndex((e) => e.id === this.heatingType.energy_carrier_id)
    },

    //
    energyCarrier() {
      return this.portfolio.energy_carriers[this.energyCarrierIndex]
    },

    //
    energyCarrierTranslated() {
      return this.portfolio.getEnergyCarrierName(
        (k) => this.$t(k),
        this.energyCarrierIndex
      )
    },

    //
    energyCarrierClasses() {
      return {
        'dynamic': ['ELECTRICITY_DYNAMIC', 'ELECTRICITY_DYNAMIC_WITH_PV'].includes(this.energyCarrier.name)
      }
    },
  },

  watch: {
    heatingType() {
      this.resetModel()
    },
  },

  created() {
    if (!this.heatingType) {
      this.onFormEvent('edit')
    }
    this.resetModel()
  },

  methods: {
    //
    ...mapActions({
      updateHeatingType: 'portfolio/updateHeatingType',
      addHeatingType: 'portfolio/addHeatingType',
    }),

    //
    resetModel() {
      if (this.heatingType) {
        this.model =
          this.heatingType.type === 'HEAT_PUMP'
            ? {
                name: this.heatingType.name,
                portfolio_id: this.heatingType.portfolio_id,
                energy_carrier_id: this.heatingType.energy_carrier_id,
                grid_efficiency: this.heatingType.grid_efficiency,
                heatpump_grade: this.heatingType.heatpump_grade,
                source: this.heatingType.source,
                type: this.heatingType.type,
              }
            : {
                name: this.heatingType.name,
                portfolio_id: this.heatingType.portfolio_id,
                energy_carrier_id: this.heatingType.energy_carrier_id,
                grid_efficiency: this.heatingType.grid_efficiency,
                boiler_rh_efficiency: this.heatingType.boiler_rh_efficiency,
                boiler_dhw_efficiency: this.heatingType.boiler_dhw_efficiency,
                type: this.heatingType.type,
              }
      } else {
        this.model =
          this.type === 'HEAT_PUMP'
            ? {
                name: '',
                portfolio_id: this.portfolio.id,
                energy_carrier_id: null,
                grid_efficiency: 1,
                heatpump_grade: 1,
                source: 'GENERIC',
                type: this.type,
              }
            : {
                name: '',
                portfolio_id: this.portfolio.id,
                energy_carrier_id: null,
                grid_efficiency: 1,
                boiler_rh_efficiency: 1,
                boiler_dhw_efficiency: 1,
                type: this.type,
              }
      }
    },

    //
    async saveHandler() {
      try {
        if (this.heatingType) {
          await this.updateHeatingType({ id: this.heatingType.id, heatingType: this.model })
        } else {
          await this.addHeatingType(this.model)
        }
      } catch (error) {
        this.$emit('error', error)
      }
    },
  },
}
</script>
<style>
.edit-heating-type .align-right {
  text-align: right;
  justify-content: flex-end;
}
</style>
