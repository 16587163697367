<i18n>
{
  "de": {
    "pageTitle": "Heizungstypen",
    "settings": "Einstellungen",
    "updateParamsPendingMessage": "Parameter werden gespeichert...",
    "saveButtonLabel": "Speichern",
    "cancelButtonLabel": "Abbrechen",
    "returnButton": "Zurück",
    "typeTitle": "Typ",
    "energyCarrierTitle": "Energieträger",
    "rhEfficiency": {
      "title": "Nutzungsgrad Raumwärme",
      "infoBox": {
        "title": "Nutzungsgrad Heizung",
        "body": "Verhältnis von Nutzenergie Raumwärme und Endenergie. Beinhaltet Verluste der Wärmeerzeugung und Wärmeverteilung (Standardwerte in SIA 380:2015 Anhang J.2, Tabelle 13)"
      }
    },
    "dhwEfficiency": {
      "title": "Nutzungsgrad Warmwasser",
      "infoBox": {
        "title": "Nutzungsgrad Warmwasser",
        "body": "Verhältnis von Nutzenergie Warmwasser und Endenergie. Beinhaltet Verluste der Wärmeerzeugung und Warmwasserverteilung (Standardwerte in SIA 380:2015 Anhang J.2, Tabelle 13)"
      }
    },
    "gridEfficiency": {
      "title": "Wärmenetz Effizienz",
      "infoBox": {
        "title": "Wärmenetz Effizienz",
        "body": "Wenn Heizungstypen in einem Nahwärmenetz (siehe Einstellungen > Wärmenetze) genutzt werden, verändert sich die Effizienz. Dieser Faktor bestimmt, wie stark die Effizienz sinkt/steigt."
      }
    },
    "heatpumpGrade": {
      "title": "Gütegrad",
      "infoBox": {
        "title": "Gütegrad von Wärmepumpen",
        "body": "Die Jahresarbeitszahl von Wärmepumpen wird in Abhängigkeit der monatlichen Quellentemperatur (Aussenluft bzw. Erdreich-/Grundwasser) und Abgabetemperaturen (Warmwassertemperatur bzw. Vorlauftemperatur) berechnet. Der Gütegrad gibt die Effizienz von Wärmepumpen an, bezogen auf die maximale theoretisch erreichbare Arbeitszahl (Carnot-Arbeitszahl)"
      }
    },
    "source": {
      "title": "Wärmequelle",
      "infoBox": {
        "title": "Wärmequelle",
        "body": "Anhand der Art der Quelle wird die Quellentemperatur der Wärmepumpe ermittelt. Luft: Gemessene Umgebungstemperatur, Grundwasser: 10 °C, Erdwärme: 4 °C, Generisch: 10 °C"
      }
    },
    "addHeatingButton": "Heizung erstellen",
    "addHeatpumpButton": "Wärmepumpe erstellen",
    "confirmDelete": "Wollen Sie diesen Heizungstypen wirklich löschen?",
    "noBoilerRecords": "Für dieses Portfolio sind noch keine Boiler vorhanden.",
    "noHeatpumpRecords": "Für dieses Portfolio sind noch keine Wärmepumpen vorhanden."
  }
}
</i18n>

<template>
  <MainLayout :portfolio="portfolio" :ready="portfolioReady">
    <template #default>
      <PageTitle :title="$t('pageTitle')" :portfolio="portfolio">
        <template #default>
          <div>/</div>
          <router-link :to="`/portfolios/${portfolio.id}/settings`">{{ $t('settings') }}</router-link>
        </template>
      </PageTitle>

      <div v-if="error">
        {{ error }}
      </div>
      <div v-if="pending">
        {{ $t('pendingMessage') }}
      </div>

      <DetailList v-if="boilerTypes.length" has-header class="heating-types-table">
        <template #header>
          <span>{{ $t('typeTitle') }}</span>
          <span>{{ $t('energyCarrierTitle') }}</span>
          <span class="align-right">
            <span>{{ $t('gridEfficiency.title') }}</span>
            <InfoBox :title="$t('gridEfficiency.infoBox.title')">{{ $t('gridEfficiency.infoBox.body') }}</InfoBox>
          </span>
          <span class="align-right">
            <span>{{ $t('rhEfficiency.title') }}</span>
            <InfoBox :title="$t('rhEfficiency.infoBox.title')">{{ $t('rhEfficiency.infoBox.body') }}</InfoBox>
          </span>
          <span class="align-right">
            <span>{{ $t('dhwEfficiency.title') }}</span>
            <InfoBox :title="$t('dhwEfficiency.infoBox.title')">{{ $t('dhwEfficiency.infoBox.body') }}</InfoBox>
          </span>
          <span v-if="getPortfolioPermission('EDIT_HEATING_TYPES')"></span>
        </template>
        <li v-for="(heatingType, index) in boilerTypes" :key="index">
          <div>
            <span v-if="heatingType.default">{{ $t(`_heatingTypes.${heatingType.name}`) }}</span>
            <span v-else>{{ heatingType.name }}</span>
          </div>
          <div>
            <span :class="{ dynamic: getDynamicEnergyCarrier(heatingType.energy_carrier_id) !== undefined }">
                {{ getEnergyCarrierName(heatingType.energy_carrier_id) }}
              <ECDynamicInfoBox :type="getDynamicEnergyCarrier(heatingType.energy_carrier_id)">
                {{ $t('ecDynamic.body') }}
              </ECDynamicInfoBox>
            </span>
          </div>
          <span class="align-right">{{ heatingType.grid_efficiency }}</span>
          <span class="align-right">{{ heatingType.boiler_rh_efficiency }}</span>
          <span class="align-right">{{ heatingType.boiler_dhw_efficiency }}</span>
          <ListButtonWrapper v-if="getPortfolioPermission('EDIT_HEATING_TYPES')" class="align-right">
            <ListEditButton @click="onEditModalOpen(heatingType, heatingType.type)" />
            <ListDeleteButton
              v-if="!heatingType.default"
              :disable="heatingType.default"
              @click="onDelete(heatingType)"
            />
          </ListButtonWrapper>
        </li>
      </DetailList>
      <div v-else>
        {{ $t('noBoilerRecords') }}
      </div>
      <ButtonWrapper>
        <a
          v-if="getPortfolioPermission('EDIT_HEATING_TYPES')"
          class="button"
          href="#"
          @click.prevent="onEditModalOpen(null, 'BOILER')"
          >{{ $t('addHeatingButton') }}</a
        >
      </ButtonWrapper>
      <DetailList v-if="heatpumpTypes.length" has-header class="heating-types-table">
        <template #header>
          <span>{{ $t('typeTitle') }}</span>
          <span>{{ $t('energyCarrierTitle') }}</span>
          <span>
            <span>{{ $t('source.title') }}</span>
            <InfoBox :title="$t('source.infoBox.title')">{{ $t('source.infoBox.body') }}</InfoBox>
          </span>
          <span class="align-right">
            <span>{{ $t('gridEfficiency.title') }}</span>
            <InfoBox :title="$t('gridEfficiency.infoBox.title')">{{ $t('gridEfficiency.infoBox.body') }}</InfoBox>
          </span>
          <span class="align-right">
            <span>{{ $t('heatpumpGrade.title') }}</span>
            <InfoBox :title="$t('heatpumpGrade.infoBox.title')">{{ $t('heatpumpGrade.infoBox.body') }}</InfoBox>
          </span>
          <span v-if="getPortfolioPermission('EDIT_HEATING_TYPES')"></span>
        </template>
        <li v-for="(heatingType, index) in heatpumpTypes" :key="index">
          <span>
            <div v-if="heatingType.default">{{ $t(`_heatingTypes.${heatingType.name}`) }}</div>
            <div v-else>{{ heatingType.name }}</div>
          </span>
          <span>
            <span :class="{ dynamic: getDynamicEnergyCarrier(heatingType.energy_carrier_id) !== undefined }">
              {{ getEnergyCarrierName(heatingType.energy_carrier_id) }}
              <ECDynamicInfoBox :type="getDynamicEnergyCarrier(heatingType.energy_carrier_id)">
                {{ $t('ecDynamic.body') }}
              </ECDynamicInfoBox>
            </span>
          </span>
          <span>{{ $t(`_heatPumpSources.${heatingType.source}`) }}</span>
          <span class="align-right">{{ heatingType.grid_efficiency }}</span>
          <span class="align-right">{{ heatingType.heatpump_grade }}</span>
          <ListButtonWrapper v-if="getPortfolioPermission('EDIT_HEATING_TYPES')" class="align-right">
            <ListEditButton @click="onEditModalOpen(heatingType, heatingType.type)" />
            <ListDeleteButton
              v-if="!heatingType.default"
              :disable="heatingType.default"
              @click="onDelete(heatingType)"
            />
          </ListButtonWrapper>
        </li>
      </DetailList>
      <div v-else>
        {{ $t('noHeatpumpRecords') }}
      </div>
      <ButtonWrapper>
        <a
          v-if="getPortfolioPermission('EDIT_HEATING_TYPES')"
          class="button"
          href="#"
          @click.prevent="onEditModalOpen(null, 'HEAT_PUMP')"
          >{{ $t('addHeatpumpButton') }}</a
        >
      </ButtonWrapper>
      <EditHeatingTypeModal
        v-if="editModalOpen"
        :portfolio="portfolio"
        :heating-type="editHeatingType"
        :type="type"
        @close="onEditModalClose"
      />
    </template>
  </MainLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import MainLayout from '@/pages/layouts/MainLayout.vue'
import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin.vue'
import PortfolioMixin from '@/pages/vuex-mixins/PortfolioMixin.vue'

import ListButtonWrapper from '@/components/shared/lists/ListButtonWrapper.vue'
import ListDeleteButton from '@/components/shared/lists/ListDeleteButton.vue'
import ListEditButton from '@/components/shared/lists/ListEditButton.vue'
import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'
import InfoBox from '@/components/shared/InfoBox.vue'
import EditHeatingTypeModal from '@/components/settings/heating_types/EditHeatingTypeModal.vue'
import PageTitle from '@/components/shared/PageTitle.vue'
import ECDynamicInfoBox from '@/components/shared/ECDynamicInfoBox.vue'

export default {
  name: 'settingsHeatingTypes',

  mixins: [
    AllPortfoliosMixin, // Provides: allPortfolios, allPortfoliosReady
    PortfolioMixin, // Provides: portfolio, portfolioLoading, portfolioReady
  ],
  components: {
    ECDynamicInfoBox,
    ListButtonWrapper,
    ListEditButton,
    ListDeleteButton,
    ButtonWrapper,
    DetailList,
    InfoBox,
    MainLayout,
    EditHeatingTypeModal,
    PageTitle,
  },
  data: function () {
    return {
      editHeatingType: null,
      type: '',
      editModalOpen: false,
      error: null,
      pending: false,
    }
  },
  computed: {
    ...mapGetters({
      getPortfolioPermission: 'permissions/getPortfolioPermission',
    }),

    boilerTypes() {
      return this.portfolio.heating_types.filter((type) => type.type === 'BOILER')
    },

    heatpumpTypes() {
      return this.portfolio.heating_types.filter((type) => type.type === 'HEAT_PUMP')
    },
  },

  methods: {
    ...mapActions({
      deleteHeatingType: 'portfolio/deleteHeatingType',
    }),

    getEnergyCarrierName(id) {
      const energyCarrier = this.portfolio.energy_carriers.find((energyCarrier) => id === energyCarrier.id)
      if (energyCarrier) {
        if (energyCarrier.default) {
          return this.$t(`_energyCarriers.${energyCarrier.name}`)
        } else {
          return energyCarrier.name
        }
      } else {
        return this.$t('_energyCarriers.UNKNOWN')
      }
    },

    getDynamicEnergyCarrier(id) {
      const energyCarrier = this.portfolio.energy_carriers.find((energyCarrier) => id === energyCarrier.id)
      if (energyCarrier) {
        if (energyCarrier.name === 'ELECTRICITY_DYNAMIC' || energyCarrier.name === 'ELECTRICITY_DYNAMIC_WITH_PV') {
          return energyCarrier.name
        }
      }
      return undefined
    },

    onEditModalOpen(editHeatingType, type) {
      this.type = type
      this.editHeatingType = editHeatingType
      this.editModalOpen = true
    },

    onEditModalClose(e) {
      this.editModalOpen = false
    },

    async onDelete(type) {
      if (!type.default) {
        if (confirm(this.$t('confirmDelete'))) {
          try {
            this.error = null
            this.pending = true
            await this.deleteHeatingType({ id: type.id, portfolioId: type.portfolio_id })
          } catch (error) {
            this.error = error
          }
          this.pending = false
        }
      }
    },
  },

  head() {
    return {
      title: () => {
        return { inner: this.$t('pageTitle') }
      },
    }
  },
}
</script>

<style>
.heating-types-table .align-right {
  text-align: right;
  justify-content: flex-end;
}

.dynamic {
  font-style: italic;
}
</style>
